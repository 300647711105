
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import { ElNotification } from 'element-plus';
import axios from 'axios';
import Multiselect from '@vueform/multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-on-going-course-summary-report',
  components: {
    Datatable,
    Multiselect,
  },
  data() {
    return {
      batch: {
        tranche_id: [] as any,
        start_date: '',
        end_date: '',
      },
      api_url: '',
      data: [],
      tranches_info: [] as any,
      optionsTranche: [] as any,
      optionsTPartner:[] as any,
      componentKey: 0,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranches();
  },
  methods: {

    async printPdf() {
      let formData = new FormData();
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);


      let data = `${this.VUE_APP_API_URL}/api/report/on-going-course_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
       ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);

      await ApiService.post('report/on-going-course_pdf', formData)
        .then((response) => {
           ElNotification.closeAll();
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
       ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('start_date', this.batch.start_date);
      formData.set('end_date', this.batch.end_date);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/ongoungcourseexport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
           ElNotification.closeAll();
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'OnGoingCourseSummary.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async formSubmit() {
      //form submit
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
            this.tranches_info.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    trainingProviderNotice() {
      if(this.batch.tranche_id){
        this.loading = true;
        ApiService.get(
          'report/on-going-course-summary?tranche_id=' +
          this.batch.tranche_id +
          '&start_date=' +
          this.batch.start_date +
          '&end_date=' +
          this.batch.end_date
        )
          .then((response) => {
            this.loading = false;
            this.data = response.data.data;
            this.showCourseNotice = true;
            console.log(response);
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select tranche first.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }
    },
  },
});
